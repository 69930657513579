<template>
  <div class="vx-row">
    <div class="vx-col w-2/3">
      <vx-card>
        <h1 class="text-info">Crear notificación</h1>
        
        <vs-input v-model="message.title" label="Título" class="mt-5 w-full" />

        <vs-textarea v-model="message.body" label="Texto" class="mt-5 w-full" />
      </vx-card>
    </div>

    <!-- vista previa -->
    <div class="vx-col w-1/3">
      <vx-card>
        <h2 class="text-info">Vista Previa</h2>
        <div class="mt-5 android preview-background" :style="{ backgroundImage: 'url(' + image_android + ')' }">
          <div class="mat-card mat-focus-indicator banner-card">
            <div class="banner-content">
              <div class="banner-text">
                <div class="title"> {{ message.title  || 'Título' }} </div>
                <div  class="text"> {{ message.body || 'Texto' }} </div>
              </div>
              <img class="banner-image ng-star-inserted" :src="message.image || image_placeholder">
            </div>
          </div>
        </div>
      </vx-card>
    </div>

    <!-- Fecha -->
    <div class="vx-col w-1/2 mt-5">
      <vx-card>
        <h2 class="text-info">Fecha de envío</h2>

        <div class="mt-5">
          <label for="date" class="block">Seleccione fecha de envío</label>
          
          <div class="my-2 flex space-x-4">
            <vs-switch id="early" v-model="early"/>
            <label for="early">Tan pronto como sea posible</label>
          </div>
              
          <flat-pickr v-if="!early" id="date" :config="configdateTimePicker" v-model="date" placeholder="Escoja una fecha"/>
          <br/>
        </div>

      </vx-card>
    </div>

    <!-- Imagen -->
    <div class="vx-col w-1/2 mt-5">
      <vx-card>
        <h2 class="text-info">Agregar imagen</h2>

        <div class="flex items-center space-x-6">
          <div class="shrink-0">
            <img class="w-full rounded-full h-24 w-24" :src="image.path || image_placeholder">
            <vs-progress v-show="image.progress !== 100 && image.path" :percent="image.progress" color="primary"/>
          </div>

          <label class="block" for="file">
            <input id="file" type="file" class="hidden" @change="change" ref="imageInput" accept="image/*"/>
            <span class="sr-only">Seleccionar imagen</span>
            <button v-if="!image.path" class="vs-button bg-primary rounded-full" @click="clickImageInput()">
              Seleccionar archivo
            </button>
            <button v-else class="vs-button bg-danger rounded-full vs-button-filled small" @click="removeImage()">
              <feather-icon icon="Trash2Icon" class="w-5 h-6"/>
            </button>
          </label>
        </div>
      </vx-card>
    </div>

    <!-- Color y categoría -->
    <div class="vx-col w-full mt-5">
      <vx-card>
        <h2 class="text-info">Color y categoría</h2>

        <div class="flex space-between">
          <div class="mt-5 w-1/2">
            <label for="color"><b>Seleccione color de notificación</b></label><br/>
            <input type="color" v-model="message.color" id="color"/>
          </div>

          <div class="mt-5 w-1/2">
            <vs-input v-model="message.type_" label="Categoría" class="w-full" />
            <i class="text-sm">P. ej. Promociones</i>
          </div>
        </div>
      </vx-card>
    </div>

    <!-- Usuarios -->
    <div class="vx-col w-full mt-5">
      <vx-card>
        <h2 class="text-info">Usuarios</h2>
        
        <div class="my-4 flex justify-between">
          <div class="flex space-x-4">
            <vs-switch id="all_users" v-model="all_users"/>
            <label for="all_users">Todos los usuarios activos en el app</label>
          </div>
          <!-- download button -->
          <button v-if="!all_users" @click="downloadFileFormat()" class="vs-component vs-button rounded-full vs-button-info vs-button-filled small lg:float-right sm:mt-5">
            <img :src="require(`@/assets/icons/DownloadFileIcon.svg`)" width="20px" class="inline-block align-middle mr-2"/>
            Descargar formato de ejemplo
          </button>
        </div>

        <div v-if="!all_users" class="flex items-center space-x-6 mt-5">
          <div class="shrink-0">
            <img class="w-full h-24 w-24" :src="require('@/assets/icons/ExcelIcon.svg')">
            <vs-progress v-show="xlsx.progress !== 100" :percent="xlsx.progress" color="primary"/>
            <p v-if="xlsx.file"> {{ xlsx.file.name }} </p>
          </div>

          <label class="block" for="xlsx">
            <input id="xlsx" type="file" class="hidden" @change="changeXlsx" ref="xlsxInput" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
            <button v-if="!xlsx.path" class="vs-button bg-primary rounded-full" @click="clickXlsxInput()">
              Seleccionar archivo
            </button>
            <button v-else class="vs-button bg-danger rounded-full vs-button-filled small" @click="removeXlsx()">
              <feather-icon icon="Trash2Icon" class="w-5 h-6"/>
            </button>
          </label>
        </div>
      </vx-card>
    </div>

    <!-- Guardar -->
    <div class="vx-col w-full mt-5">
      <vx-card>
        <div class="flex justify-center">
          <vs-button @click="save" class="rounded-full">
            Enviar
          </vs-button>
        </div>
      </vx-card>
    </div>
    
  </div>
</template>

<script lang="ts">
import Vue       from 'vue';
import vSelect   from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.min.css';

export default Vue.extend({

  data() {
    return {
      configdateTimePicker: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        minDate  : new Date(),
      },
      image_android     : require('@/assets/images/elements/android.png'),
      image_placeholder : require('@/assets/images/elements/image_placeholder.png'),
      image : {
        progress : 0,
        file     : null,
        path     : '',
      },
      xlsx : {
        progress : 0,
        file     : null,
        path     : '',
      },
      date : new Date(),
      early : false,
      all_users : false,
    }
  },

  watch : {
    'image.path'(val) { this.message.image = val; },
    'xlsx.path'(val) { this.message.bucket_path = val; },
    date(val) {
      if( typeof val === 'string' ){
        const date = new Date(val);
        this.message.date_ts = date.getTime();
        
      } else {
        this.message.date_ts = val;
      }
    },
    early(val) {
      if(val) this.date = null;
      else this.setDate();
    },
    all_users(val) { if( val ) this.message.bucket_path = ''; },
  },

  created() {
    this.setDate();
  },

  methods : {
    async save() {
      const title = this.message.title;
      // validate title
      if(!title) {
        this.$vs.notify({
          title   : 'Error',
          text    : 'Debe ingresar un título',
          color   : 'danger',
          icon    : 'warning',
          position: 'top-right',
        });
        return;
      }

      this.$vs.loading();
      const res = await this.$store.dispatch('messages/create', this.message);
      this.$vs.loading.close();

      if( res ) {
        this.$router.push({ path : '/app/push/list' });
        // set success notification
        this.$vs.notify({
          title   : 'Notificación creada',
          text    : 'Revise las notificaciones para revisar el estado',
          color   : 'success',
          icon    : 'check',
          position: 'top-right',
        });
        
      }
    },
    clickImageInput() { this.$refs.imageInput.click(); },
    clickXlsxInput() { this.$refs.xlsxInput.click(); },
    change(ev) {
      const file = ev.target.files[0];

      // Validate image type
      if (!file.type.match('image.*')) {
        this.$vs.notify({
          title: 'Error',
          text : 'El archivo no es una imagen',
          color: 'danger',
        });
        return;
      }

      // validate file size < 1Mb
      if (file.size > 1024 * 1024) {
        this.$vs.notify({
          title: 'Error',
          text : 'El archivo es muy grande',
          color: 'danger',
        });
        return;
      }

      this.image.file = file;

      this.$store.dispatch('firebase/uploadImage', this.image).then((path) => {
        this.image.path = path;
      });
    },
    changeXlsx(ev) {
      const file = ev.target.files[0];

      // Validate xlsx only
      if (!file.type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
        this.$vs.notify({
          title: 'Error',
          text : 'El archivo no es un archivo de Excel',
          color: 'danger',
        });
        return;
      }

      this.xlsx.file = file;

      this.$store.dispatch('firebase/uploadDocument', this.xlsx).then((path='') => {
        this.xlsx.path = path;
      });
    },
    removeImage() {
      this.image.path = '';
      this.image.file = null;
      this.image.progress = 0;
      this.message.image = '';
    },
    removeXlsx() {
      this.xlsx.path = '';
      this.xlsx.file = null;
      this.xlsx.progress = 0;
      this.message.bucket_path = '';
    },
    setDate() {
      this.date = new Date().setMinutes(30);
      this.configdateTimePicker.minDate = this.date;
      setTimeout(() => {
        let date = new Date();
        date = new Date(date.setMinutes(30));
        this.message.date_ts = date.getTime();
        
      }, 1);
    },
    downloadFileFormat() {
      this.$vs.loading();
      this.$store.dispatch( 'getFileUrl', '/documents/example-formats/messaging.xlsx' )
        .then( url => {
          this.$vs.loading.close();
          if( url ) {
            const link = document.createElement('a');    
            link.setAttribute( 'download', 'true' );
            link.href = url;
            link.click();
            link.remove();
          }
        });
    },
  },

  computed : {
    message() {
      const store = this.$store;
      const msg = store.state.messages.message;
      let msgProxy = new Proxy( msg, {
        set (obj, key, value) {
          store.commit('messages/SET_MESSAGE', {
            ...obj,
            [key]: value,
          });    
          return true
        }
      });

      return msgProxy;
    },
  },

  components : {
    vSelect,
    flatPickr,
  },
})
</script>

<style lang="scss" scoped>
.android.preview-background {
  padding: 15% 4.6632124352% 0;
}

.preview-background {
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  margin: 4px;
}

.notification-phone-preview {
  text-align: center;
}

div.banner-card {
  overflow: hidden;
  width: 100%;
}

.banner-card {
  border-radius: 0px;
  margin: 4px;
  padding: 8px;
}

.banner-content{
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  height: 50%;
  padding: 10px;
  height: 90%;
}

.banner-image {
  width: 30%;
}

.banner-text {
  min-height: 28px;
  text-align: left;
  overflow: hidden;
}

.title {
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.text {
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.banner-text {
  font-size: 10px;
  min-height: 28px;
  text-align: left;
  overflow: hidden;
}
</style>
